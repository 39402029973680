@import '../../styles/variables';

.container {
  flex: 1 1 100%;
  padding: 40px;
  overflow: auto;
}

.innerContainer {
  margin-bottom: 25vh;
}

.labels {
  display: flex;
  font-size: 14px;
}

.dayLabel {
  color: $foreground50;
}

.todayLabel {
  margin-left: 8px;
  color: $highlight;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
}

.title {
  font-size: 36px;
  font-weight: 700;
}

.icon,
.name {
  display: inline-block;
  vertical-align: middle;
}

.icon {
  margin-right: increment(2);
}

.blocks {
  margin-top: 40px;
}

.todayButton {
  margin-right: 12px;
}
