@import '../../styles/variables';

.container {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: $br;
  color: $foreground70;
  vertical-align: top;
  font-size: 13px;

  svg {
    fill: $foreground70;
  }

  &:hover {
    color: $foreground;

    svg {
      fill: $foreground;
    }
  }
}

.icon {
  display: block;

  svg {
    display: block;
  }
}

[data-size="base"] {
  padding: increment(2);

  &.hasText {
    .icon {
      margin-right: increment(2);
    }
  }
}

.stroked {
  padding: increment(2) increment(4);
  border: 1px solid $foreground10;

  &:hover {
    border: 1px solid $highlight;
  }
}

.dashed {
  padding: increment(2) increment(4);
  border: 1px dashed $foreground10;

  &:hover {
    border: 1px solid $highlight;
  }
}

.icon {
  padding: 0;
  width: 24px;
  height: 24px;
}
