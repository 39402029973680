@import '../../styles/variables';

.container {
  position: relative;
  display: block;
  border-radius: $br;

  &:hover {
    background: $foreground025;
  }
}

.isSelected,
.isSelected:hover {
  background: $highlight075;
}
