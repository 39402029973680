@import '../../styles/variables';

.container {
  padding: increment(5);
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: increment(3);
}

.weekRow {
  display: flex;
}

.day,
.dayName {
  flex: 1 0 calc(100% / 7);
  font-size: 10px;
  line-height: 2rem;
  text-align: center;
}

.day {
  cursor: pointer;

  &:not(.isCurrent):hover {
    .dayText {
      background: $foreground05;
    }
  }
}

.dayText {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 22px;
}

.dayName {
  font-weight: bold;
}

.isNeighbor {
  color: $foreground20;
}

.isCurrent {
  .dayText {
    background: $highlight;
    color: $background;
  }
}

.isToday {
  .dayText {
    box-shadow: inset 0 0 0 1px $highlight;
  }

  &:hover {

  }
}

.quickOptions {
  margin-top: increment(3);
}
