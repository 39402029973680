// colors
:root {

  // tada constants
  --white:        255, 255, 255;
  --light-white:  245, 246, 250;
  --black:        21, 22, 25;
  --pureBlack:    0, 0, 0;

  --blue:         98, 122, 255;
  --pink:         249, 65, 197;
  --orange:       252, 174, 57;
  --green:        17, 197, 100;
  --purple:       156, 21, 219;
  --yellow:       239, 218, 32;
  --red:          230, 29, 91;

  // original theme
  --background:   var(--white);
  --foreground:   var(--black);
  --highlight:    var(--blue);

  --themeOverlayOpacity: 0.2;
  --cardModalOffset: 16px;
}

$background:      rgba(var(--background), 1);
$background60:    rgba(var(--background), 0.6);
$foreground:      rgba(var(--foreground), 1);
$foreground025:   rgba(var(--foreground), 0.025);
$foreground03:    rgba(var(--foreground), 0.03);
$foreground05:    rgba(var(--foreground), 0.05);
$foreground10:    rgba(var(--foreground), 0.1);
$foreground20:    rgba(var(--foreground), 0.2);
$foreground30:    rgba(var(--foreground), 0.3);
$foreground50:    rgba(var(--foreground), 0.5);
$foreground70:    rgba(var(--foreground), 0.7);
$foreground80:    rgba(var(--foreground), 0.8);
$highlight:       rgba(var(--highlight), 1);
$highlight05:     rgba(var(--highlight), 0.05);
$highlight075:     rgba(var(--highlight), 0.075);
$highlight10:     rgba(var(--highlight), 0.1);

$blue: rgba(var(--blue), 1);
$pink: rgba(var(--pink), 1);
$orange: rgba(var(--orange), 1);
$green: rgba(var(--green), 1);
$purple: rgba(var(--purple), 1);
$yellow: rgba(var(--yellow), 1);
$red: rgba(var(--red), 1);

$black: rgba(var(--black), 1);
$black20: rgba(var(--black), 0.2);
$black50: rgba(var(--black), 0.5);
$pureBlack: rgba(var(--pureBlack), 1);
$pureBlack10: rgba(var(--pureBlack), 0.1);
$pureBlack20: rgba(var(--pureBlack), 0.2);
$white: rgba(var(--white), 1);

$themeOverlay: rgba(var(--pureBlack), var(--themeOverlayOpacity));


// typography
// $bodyFont: InterUI-Regular, objektiv-mk3, -apple-system, BlinkMacSystemFont, 'Roboto', 'Open Sans', sans-serif;
// $bodyFont: objektiv-mk3, -apple-system, BlinkMacSystemFont, 'Roboto', 'Open Sans', sans-serif;
$bodyFont: -apple-system, BlinkMacSystemFont, 'Roboto', 'Open Sans', sans-serif;
$mono: 'Source Code Pro', Monaco, monospace;

// dimensions
$increment: 4px;
$br: 6px;
$brBig: 15px;
$headerHeight: 48px;
$primaryNavHeight: 72px;
$navTransition: 0.23s ease;

// mixins
@mixin center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

@mixin button {
  display: inline-block;
  vertical-align: top;
  height: 32px;
  padding: 0 20px;
  font-size: 12px;
  text-align: center;
  line-height: 32px;
  background: $foreground10;
  border-radius: $br;

  &:hover {
    background: $foreground20;
    box-shadow: none;
  }
}

@mixin select {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 32px;
  padding: 0 32px 0 12px;
  font-size: 12px;
  line-height: 32px;
  box-shadow: inset 0 0 0 1px $foreground10;
  background: $background;
  border-radius: $br;

  &:hover {
    box-shadow: inset 0 0 0 1px $edge15;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 6px;
    bottom: 0;
    width: 16px;
    height: 16px;
    margin: auto;
    // background: url(./assets/arrowhead-down.svg);
    pointer-events: none;
  }
}

@mixin popupMenu {
  position: fixed;
  z-index: 100000;
  padding: 8px 0;
  background: $background;
  border-radius: $br;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.16), 0 8px 72px rgba(0, 0, 0, 0.09);
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2), 0 15px 20px -10px rgba(0, 0, 0, 0.15);
  // box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.2);
}

@mixin draggable {
  -webkit-app-region: drag;
  -webkit-user-select: none;
}

@mixin textLink {
  color: $foreground;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

@function increment($num) {
  @return $increment * $num;
}
