@import './../styles/reset';
@import './../styles/variables';

* {
  box-sizing: border-box;
}

body {
  background: $background;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  line-height: 1.4;
}

body,
input,
textarea,
select {
  margin: 0;
  color: $foreground;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  -webkit-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a, a:hover, a:visited {
  color: inherit;
  text-decoration: none;
}

input,
textarea,
select {
  background: $foreground05;
  border-radius: $br;
  border: none;
  padding: increment(1) increment(2);

  &:focus {
    box-shadow: inset 0 0 0 1px $highlight;
  }
}

.main {
  display: flex;
  height: 100vh;
}

.contentWrapper {
  display: flex;
  flex: 1 1 auto;
}