@import '../../styles/variables';

.container {
  position: fixed;
  z-index: 100000;
  background: $background;
  border-radius: $br;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.menu-item {
  display: flex;
  font-size: 12px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background: $highlight;
    color: $white;
  }
}

.menuItemLabel {

}
