@import '../../styles/variables';

.container {

}

.newTaskInputWrapper {
  position: relative;
  margin-bottom: 20px;
}

.newTaskIcon {
  position: absolute;
  top: 12px;
  left: increment(3);
  fill: $foreground50;
}

.newTaskInput {
  display: block;
  width: 100%;
  padding: 12px;
  text-indent: 32px;
  background: $foreground025;
  cursor: text;

  &:hover {
    background: $foreground05;
  }

  &:focus + .newTaskIcon {
    fill: $highlight;
  }
}

.inserter {
  height: 0;
  width: 100%;
  box-shadow: 0 0 0 1px $highlight;
  overflow: hidden;
}

.isHidden {
  opacity: 0.7;
  background: $highlight10;
}
