@import '../../styles/variables';

.container {
  display: inline-block;
  cursor: text;
  padding: 0 increment(1);
  margin: 0 increment(-1);
  border-radius: 2px;

  &:hover {
    background: $background;
    box-shadow: 0 0 0 1px $foreground10;
  }

  &:focus {
    background: $background;
    box-shadow: 0 0 0 1px $highlight;
    outline: none;
  }
}
