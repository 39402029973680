@import '../../styles/variables';

.container {
  display: grid;
  grid-template-columns: 24px 1fr 32px;
  padding: increment(2);
  align-items: center;
  border-radius: $br;
  cursor: pointer;

  &:hover {
    background: $foreground025;
  }

  &.isDropTarget {
    box-shadow: inset 0 0 1px $highlight;
  }
}

.isSelected,
.isSelected:hover {
  background: $highlight075;
}

.icon {
  display: block;

  svg {
    display: block;
  }
}

.title {
  margin-left: increment(3);
}
