@import '../../styles/variables';

$checkWidth: 24px;
$halfWidth: 12px;

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: increment(2) increment(3);
  transition: opacity 0.12s 0.8s;

  &.isChecking {
    opacity: 0;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }
}

.graphic {
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.12s;
}

.checkboxWrapper {
  flex: 0 0 auto;
  position: relative;
  width: 24px;
  margin-right: increment(3);
  cursor: pointer;

  &:hover {
    // .graphic {
    //   transform: scale(1.2);
    // }
  }

  &:active {
    .graphic {
      transform: scale(0.85);
    }
  }
}

.checkmark {
  stroke: $highlight;
  stroke-width: 1.75;
  fill: none;
  // stroke-linecap: round;
  // stroke-linejoin: round;
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
}

.checkbox {
  stroke: $highlight;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  transform: rotate(-90deg);
  transform-origin: $halfWidth $halfWidth;
  stroke-dasharray: 50;
  transition: stroke-dashoffset 0.4s, transform 0.4s;
}

.isChecked {
  .checkbox {
    opacity: 0;
    stroke-dashoffset: -45;
    transform: rotate(120deg);
  }

  .checkmark {
    animation: none;
    stroke-dashoffset: 0;
    stroke: $foreground30;
  }

  .text {
    text-decoration: line-through;
    color: $foreground30;
  }
}

.isChecking {
  .checkmark {
    stroke-dashoffset: 20;
    animation: STROKE_PATH 0.22s 0.12s 1 forwards;
  }
}

.content {
  flex: 1 1 auto;
  margin-top: 1px;
}

.actions {
  display: flex;
  opacity: 0;
}

.actionButton {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.star {

  svg {
    fill: $foreground10;
  }

  &.isStarred {
    svg {
      fill: $yellow;
    }
  }
}
