@import '../../styles/variables';

.container {
  border-radius: $br;
}

.xButton {
  padding: 0;
  margin-left: increment(2);
  margin-right: increment(-2)
}
