@import '../../styles/variables';

.container {
  display: grid;
  grid-template-rows: 1fr 40px;
  width: 260px;
  position: relative;
  padding: increment(3);

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 44px;
    bottom: increment(2);
    right: 0;
    width: 1px;
    background: $foreground10;
  }
}

.isHidden {
  width: 20px;

  .primaryItems,
  .listItems,
  .footer {
    display: none;
  }
}

.primaryItems,
.listItems {
  margin-top: 20px;
}

.main {
  overflow-y: auto;
  overflow-x: hidden;
}

.toggleButton {
  position: absolute;
  top: 4px;
  right: -18px;
  z-index: 100;
}

.navIcon {
  fill: $foreground;
  opacity: 0.25;

  &.isSelected {
    fill: $highlight;
    opacity: 1;
  }
}
