@import '../../styles/variables';

.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: relative;
  flex: 1 1 100%;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 44px;
    bottom: increment(2);
    left: 0;
    width: 1px;
    background: $foreground10;
  }
}

.header {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 40px;
}

.body {
  padding: 0 40px 25vh;
  overflow: auto;
}

.right {
  text-align: right;
}

.list {
  font-size: 16px;
  font-weight: 600;

  &:after {
    content: '›';
    margin-left: increment(1);
    color: $foreground30;
  }
}

.listIcon,
.listName {
  display: inline-block;
  vertical-align: middle;
}

.listIcon {
  margin-right: increment(1);
}

.title {
  font-size: 32px;
  font-weight: 700;
}

.blocks {
  margin-top: 40px;
}

.toggleButton {
  position: absolute;
  top: 4px;
  left: -18px;
  z-index: 100;
  fill: $foreground;
}

.trashIcon {
  fill: $foreground;
}

.footer {
  padding: 32px 40px;
}

.dateInput {
  display: inline-block;
  margin-right: 20px;
}
